import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  ColorToken,
  Flex,
  Grid,
  Image,
  Text,
} from '@mindfulchefuk/design-system'

import { RootState } from '@mindfulchefuk/types/store'
import { getDiscountConfig } from '@mindfulchefuk/utils/getDiscountConfig'
import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import { CTALocation } from '@mindfulchefuk/analytics/src/types'
import { CMSImage } from '@mindfulchefuk/features/CMS/components/CMSImage'
import { CMSMultiCardGroup } from '@mindfulchefuk/features/CMS/interfaces'
import { HomepageSectionContainer } from '@mindfulchefuk/features/Homepage/components/HomepageSectionContainer'

export type TMultiCardGroupProps = {
  data: CMSMultiCardGroup
  titleColor?: ColorToken
  bodyColor?: ColorToken
  mobileStacking?: boolean
  ctaLocation?: CTALocation
  useCms?: boolean
  id?: string
}

function order(columns: boolean, index?: number) {
  if (columns) {
    return 0
  } else {
    return index % 2 !== 0 ? 1 : 0
  }
}

export const MultiCardGroup = ({
  data,
  titleColor = 'aubergine',
  bodyColor = 'black',
  mobileStacking = false,
  ctaLocation,
  useCms = false,
  id,
}: TMultiCardGroupProps): ReactElement => {
  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )
  const discountConfig = getDiscountConfig(savedVoucherCode)
  const templateColumnFraction = mobileStacking ? '1fr' : '1fr 1fr'
  const mobileWidth = mobileStacking
    ? { base: '60%', sm: '75%', md: '100%' }
    : { base: '100%', sm: '85%', md: '100%' }

  return (
    <HomepageSectionContainer
      color={bodyColor}
      px={12}
      id={id ? `${id}-section` : null}
    >
      <Box textAlign="center" px={mobileStacking ? 16 : 0}>
        <Box pb={24} mb={{ base: mobileStacking ? 36 : 24, md: 48 }}>
          <Text
            variant="heading-md"
            as="h1"
            color={titleColor}
            id={id ? `${id}-heading` : null}
          >
            {data.title}
          </Text>
          {data.subtitle && (
            <Text
              variant="body-lg"
              as="p"
              maxWidth="800px"
              mx="auto"
              mt={36}
              id={`${id}-subtitle`}
            >
              {data.subtitle}
            </Text>
          )}
        </Box>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          maxWidth={{ base: '360px', sm: '480px', md: '900px' }}
          mx="auto"
          justifyContent="space-between"
          position="relative"
        >
          {data.items.map((step, index) => (
            <Grid
              data-testid={`multicard-step-${step.id}`}
              key={step.id}
              templateColumns={{ base: templateColumnFraction, md: 'none' }}
              templateRows={{ md: '230px 1fr' }}
              width={{ base: '100%', md: '270px' }}
              mb={36}
              justifyItems="center"
              alignItems={{
                base: mobileStacking ? 'flex-start' : 'center',
                md: 'initial',
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                justifyItems="center"
                order={{ base: order(mobileStacking, index), md: 0 }}
                width={mobileWidth}
                mb={{ base: 20, sm: 24 }}
              >
                {useCms ? (
                  <CMSImage
                    src={step.image.path}
                    width={300}
                    data-testid={`${id}-${index + 1}-img`}
                  />
                ) : (
                  <Image src={step.image.path} width={300} resolve />
                )}
              </Flex>

              <Box
                mx="auto"
                maxWidth={{ base: '95%', sm: '80%', md: '100%' }}
                id={id ? `${id}-${index}-copy` : null}
              >
                <Text
                  as="h4"
                  variant={{
                    base: 'body-md-500',
                    sm: 'body-lg-500',
                    md: 'body-xl-500',
                  }}
                  mb={8}
                  id={id ? `${id}-${index + 1}-title` : null}
                >
                  {step.title}
                </Text>
                <Text
                  as="p"
                  variant={{ base: 'body-md', sm: 'body-lg', md: 'body-xl' }}
                  id={id ? `${id}-${index + 1}-description` : null}
                >
                  {step.description}
                </Text>
              </Box>
            </Grid>
          ))}
        </Flex>

        <Box maxWidth="260px" margin="auto">
          <EmphasisLinebreakText
            variant={{ base: 'body-md', sm: 'body-lg', md: 'body-xl' }}
            py={16}
            text="Fully flexible subscription, <br /> skip or cancel anytime"
            emphasisText="skip or cancel anytime"
            emphasisVariant={{
              base: 'body-md-500',
              sm: 'body-lg-500',
              md: 'body-xl-500',
            }}
            id={id ? `${id}-flexible-subscription-text` : null}
          />
          <CTA
            showCopy={false}
            analytics={{
              category: ANALYTICS_CATEGORIES.homepage,
              action: 'How it works CTA clicked',
              label: 'Choose recipe button clicked',
            }}
            source={ctaLocation}
            id={id ? `${id}-CTA` : null}
          />
          <Text
            variant={{ base: 'body-sm', sm: 'body-md', md: 'body-lg' }}
            pt={12}
            id={`${id}-discount-text`}
          >
            {discountConfig?.totalDiscountSentence}
          </Text>
        </Box>
      </Box>
    </HomepageSectionContainer>
  )
}
