import React, { ImgHTMLAttributes, ReactElement } from 'react'
import Imgix, { Picture, Source } from 'react-imgix'

import { SourceProps } from '@mindfulchefuk/design-system/Image/Image'
import { CMSImageSource } from '@mindfulchefuk/features/CMS/interfaces'

export type TCMSImageProps = ImgHTMLAttributes<HTMLImageElement> &
  Omit<SourceProps, 'media'> & {
    sources?: CMSImageSource[]
    loading?: 'eager' | 'lazy'
    'data-testid'?: string
  }

function getImageUrl(src: string): string {
  return [process.env.NEXT_PUBLIC_CMS_IMGIX_URL, src].join('/')
}

export const CMSImage = ({
  className,
  src: imageSrc,
  height,
  width,
  sources = [],
  'data-testid': testId,
  alt = '',
  loading = 'eager',
  q = 50,
  fit = 'crop',
  crop = 'focalpoint',
  imgixParams,
}: TCMSImageProps): ReactElement => {
  const imgSrc = getImageUrl(imageSrc)

  // Gif optimization is not supported by default in imgix
  // so we can't use react-imgix to render gifs
  if (imgSrc.endsWith('gif')) {
    return (
      <picture>
        {sources.map(({ media, src }) => (
          <source media={media} srcSet={getImageUrl(src)} key={media} />
        ))}
        <img src={imgSrc} alt={alt} />
      </picture>
    )
  }

  return (
    <Picture>
      <>
        {sources.map(({ media, src, width: sourceWidth }) => (
          <Source
            key={media}
            src={getImageUrl(src)}
            width={sourceWidth}
            htmlAttributes={{ media }}
            imgixParams={{
              crop,
              fit,
              q,
            }}
            disableLibraryParam
          />
        ))}
      </>
      <Imgix
        className={className}
        src={imgSrc}
        height={height}
        width={width}
        htmlAttributes={{
          alt,
          loading,
          'data-testid': testId,
        }}
        imgixParams={{
          crop,
          fit,
          q,
          ...imgixParams,
        }}
        disableLibraryParam
      />
    </Picture>
  )
}
