import { ReactNode } from 'react'
import { Box, ColorToken, FlexProps } from '@mindfulchefuk/design-system'
import styled from '@emotion/styled'
import { createCSSMapper } from '@mindfulchefuk/design-system/system/utils/createCSSMapper'
import { alignment, flexParent } from '@mindfulchefuk/design-system/system'

type THomepageSectionContainer = {
  children: ReactNode
  id?: string
  bg?: ColorToken
} & FlexProps

const [flexMapper, shouldForwardProp] = createCSSMapper({
  ...alignment,
  ...flexParent,
})

export const HomepageSectionContainer = styled(Box, {
  shouldForwardProp,
})<THomepageSectionContainer>`
  padding-bottom: 32px;
  padding-top: 48px;
  ${flexMapper};
`
